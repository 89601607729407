import React, { createContext, useContext } from "react";

type ZakatSummaryPageType = "summary" | "topup";

interface ContextProps {
  page: ZakatSummaryPageType;
  setPage: React.Dispatch<React.SetStateAction<ZakatSummaryPageType>>;
}

export const ZakatSummaryPageContext = createContext<ContextProps>({
  page: "summary",
  setPage: () => {
    console.log("You need to overwrite this function");
  },
});

export const ZakatSummaryPageProvider: React.FC = ({ children }) => {
  const [page, setPage] = React.useState<ZakatSummaryPageType>("summary");

  return (
    <ZakatSummaryPageContext.Provider value={{ page, setPage }}>
      {children}
    </ZakatSummaryPageContext.Provider>
  );
};

export const useZakatSummaryPage = () => useContext(ZakatSummaryPageContext);
