import React, { FC, useCallback, useEffect, useState } from "react";
import { navigate } from "gatsby";
import FormButton from "@legacyComponents/FormButton/FormButton.component";
import {
  CharitiesContainer,
  Description,
  InnerWrapper,
  Layout,
  Spacer,
  HeaderText,
  ButtonWrapper,
  ShowMoreButton,
  LinksContainer,
  TwoColumns,
  FirstColumn,
  SecondColumn,
} from "./styles";
import CharityAllocation from "@Zakat/components/CharityAllocation/CharityAllocation.component";
import store from "@Zakat/store";
import { charityWithAllocatedValuesSelector } from "@Zakat/store/selectors/charityWithAllocatedValuesSelector";
import {
  createUpdateCharityAllocation,
  createUpdateCharityAllocationAPI,
  fetchCharityAllocationAPI,
} from "@Zakat/store/actions/charityAllocation";
import { CharityWithAllocation } from "@Zakat/store/types";
import { fetchZakatCharityAPI } from "@Zakat/store/actions/zakatCharity";
import { getUserEmail } from "@components/Login/selectors";
import { prepareForUpdateSelector } from "@Zakat/store/selectors/prepareForUpdateSelector";
import { useDispatch, useSelector } from "react-redux";
import { zakatSelector } from "@Zakat/store/selectors/zakat";
import validateForm, { FormValidation } from "./helpers";
import { useZakatSummaryPage } from "../summary/ZakatSummaryPageProvider";
import useAnalytics from "@helpers/useAnalytics";
import { getCharity } from "@components/Charity/selectors";
import { Text } from "@ifgengineering/component-library";
import ZakatPieChart from "@assets/images/zakat-pie-chart.png";

const CharityAllocationPage: FC = () => {
  const dispatch = useDispatch<typeof store.dispatch>();
  const [showMore, setShowMore] = useState(false);
  const userEmail = useSelector(getUserEmail);
  const { setPage } = useZakatSummaryPage();
  const zakat = useSelector(zakatSelector);
  const zakatAllocation = useSelector(prepareForUpdateSelector);
  const partnerCharity = useSelector(getCharity);
  const charitiesWithAllocation = useSelector(
    charityWithAllocatedValuesSelector
  );

  const isPartnerCharity = !!partnerCharity?.id;
  const sponsoredCharityAllocation = charitiesWithAllocation.find(
    (c) => c.id === partnerCharity.id
  );
  const charityCopy = isPartnerCharity && !!sponsoredCharityAllocation;

  const behindPagination =
    isPartnerCharity && !showMore && !!sponsoredCharityAllocation;

  const [formErrors, setFormErrors] = useState<FormValidation>({
    error: false,
    messages: {},
  });

  const firePageViewAnalytics = useAnalytics("pageView");

  useEffect(() => {
    const firePageView = async (eventName: string) => {
      (await firePageViewAnalytics)({ eventName });
    };
    firePageView("zakat:charityAllocation_pageViewed");
  }, []);

  const createUpdateAllocation = () => {
    if (zakatAllocation.length) {
      dispatch(
        createUpdateCharityAllocationAPI({
          updateZakatAllocationInput: zakatAllocation,
          userEmail,
          zakatId: zakat.id as number,
        })
      );
    }

    navigate("/app/top-up");
  };

  useEffect(() => {
    setFormErrors(validateForm(zakatAllocation));
  }, [zakatAllocation]);

  useEffect(() => {
    if (userEmail) {
      dispatch(fetchCharityAllocationAPI(userEmail));
    }

    dispatch(fetchZakatCharityAPI());
  }, [userEmail]);

  const charitiesToRender = useCallback((): CharityWithAllocation[] => {
    switch (true) {
      case behindPagination:
        return sponsoredCharityAllocation ? [sponsoredCharityAllocation] : [];
      case isPartnerCharity && showMore:
        return charitiesWithAllocation.sort((a) =>
          a.id === partnerCharity.id ? -1 : 1
        );
      case !isPartnerCharity:
      default:
        return charitiesWithAllocation;
    }
  }, [charitiesWithAllocation, showMore, partnerCharity]);

  console.log(ZakatPieChart);

  return (
    <Layout>
      <InnerWrapper>
        <div>
          <TwoColumns>
            <FirstColumn>
              <HeaderText>Donate to the IFG Zakat Impact Fund</HeaderText>
              {charityCopy ? (
                <Description>
                  You can donate your zakat to the charity that supported your
                  discounted/free will with IFG.
                </Description>
              ) : (
                <>
                  <Description>
                    Every year, IFG puts together a Zakat Guide of charities
                    that we think are having the biggest impact on this Ummah.
                    This year we have gone one further and set up a the IFG
                    Zakat Impact Fund - a dedicated fund to donate to the most
                    strategically impactful (and otherwise overlooked) areas for
                    the Muslim community
                  </Description>
                  <br />
                  <Description>
                    <strong>
                      IFG takes no fees, and your donation goes straight to the
                      selected organisations. Please note if you adhere to
                      narrower interpretations of zakat eligibility you would
                      consider only 60% of the Fund to be zakat eligible.
                    </strong>
                  </Description>
                </>
              )}

              <LinksContainer>
                <Text
                  type="S16"
                  color="BLUE600"
                  as="a"
                  target="_blank"
                  href="https://24915384.fs1.hubspotusercontent-eu1.net/hubfs/24915384/IFG%20Impact%20Zakat%20Fund%20Prospectus%202024.pdf"
                >
                  → IFG Zakat Impact Fund Prospectus 2024
                </Text>
              </LinksContainer>
            </FirstColumn>
            <SecondColumn>
              <img src={ZakatPieChart} />
            </SecondColumn>
          </TwoColumns>
          <br />
          <CharitiesContainer>
            {charitiesToRender().map(
              ({ id, displayName, logoKey, allocation }) => {
                return (
                  <CharityAllocation
                    testId="charity-allocation-card"
                    key={id}
                    name={displayName}
                    logoURL={logoKey}
                    allocation={allocation}
                    errorMessage={formErrors.messages[id]}
                    onBlur={(value) => {
                      if (value !== "") {
                        dispatch(
                          createUpdateCharityAllocation({
                            percentage: Number.parseFloat(value),
                            zakatCharityId: id,
                          })
                        );
                      }
                    }}
                  />
                );
              }
            )}
          </CharitiesContainer>
        </div>
      </InnerWrapper>
      <InnerWrapper>
        <ButtonWrapper>
          <Spacer />
          {partnerCharity?.id && !!sponsoredCharityAllocation && (
            <ShowMoreButton
              data-testid="show-more-button"
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? "Show me less charities" : "Show me more charities"}
            </ShowMoreButton>
          )}
          <FormButton
            text="Save & Continue"
            onClick={createUpdateAllocation}
            active={!formErrors.error}
            leftArrowOnClick={async () => {
              setPage("topup");
              await navigate("/app/summary");
            }}
            leftArrowLabel="Back"
          />
        </ButtonWrapper>
      </InnerWrapper>
    </Layout>
  );
};

export default CharityAllocationPage;
