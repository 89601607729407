import { ZakatState } from "../reducers/reducers";
import { charityWithAllocatedValuesSelector } from "./charityWithAllocatedValuesSelector";
import { CharityWithAllocation, PartialZakatAllocationInput } from "../types";

const isAllocated = (
  item: PartialZakatAllocationInput | null
): item is PartialZakatAllocationInput => {
  return !!item;
};

const getAllocation = ({
  allocation,
  zakatCharityId,
}: CharityWithAllocation) => {
  if (zakatCharityId === undefined || allocation === undefined) {
    return null;
  }

  return {
    percentage: allocation,
    zakatCharityId,
  };
};

export const prepareForUpdateSelector = (state: {
  zakat: ZakatState;
}): PartialZakatAllocationInput[] => {
  if (!state.zakat) {
    return [];
  }

  return charityWithAllocatedValuesSelector(state)
    .map(getAllocation)
    .filter(isAllocated);
};
