import { ZakatState } from "../reducers/reducers";
import { CharityWithAllocation } from "../types";

export const charityWithAllocatedValuesSelector = (state: {
  zakat: ZakatState;
}): CharityWithAllocation[] => {
  if (!state.zakat) {
    return [];
  }

  const { charities, charityAllocations } = state.zakat;

  const charitiesWithAllocation = charities.map((charity) => {
    const charityAllocation = charityAllocations.find(
      (allocation) => allocation.zakatCharity.id === charity.id
    );

    if (charityAllocation) {
      return {
        ...charity,
        allocation: charityAllocation.percentage,
        zakatCharityId: charityAllocation.zakatCharity.id,
      };
    }

    return charity;
  });

  return charitiesWithAllocation;
};
