import { ZakatState } from "../reducers/reducers";
import { Selector } from "../types";

export const zakatTopupSelector: Selector<ZakatState["topUp"]> = (state) =>
  state.zakat?.topUp;

export const zakatGiftAidSelector: Selector<ZakatState["giftAid"]> = (state) =>
  state.zakat?.giftAid;

export const zakatCharityAllocationSelector: Selector<
  ZakatState["charityAllocations"]
> = (state) => state.zakat?.charityAllocations;

export const zakatSelector: Selector<ZakatState> = (state) => state.zakat;
