import { PartialZakatAllocationInput } from "@Zakat/store/types";

const EXCEEDED_ALLOCATION_MESSAGE =
  "The total % allocated cannot more than 100%";

export type FormValidation = {
  error: boolean;
  messages: Record<string, string>;
};

const validateForm = (
  zakatAllocation: PartialZakatAllocationInput[]
): FormValidation => {
  const totalAllocation = zakatAllocation.reduce((acc, item) => {
    return acc + item.percentage;
  }, 0);

  if (totalAllocation <= 100) {
    return {
      error: false,
      messages: {},
    };
  }

  const allocationsWithErrors: FormValidation = {
    error: true,
    messages: {},
  };

  zakatAllocation.forEach((allocation) => {
    if (allocation.percentage) {
      allocationsWithErrors.messages[allocation.zakatCharityId] =
        EXCEEDED_ALLOCATION_MESSAGE;
    }
  });

  return allocationsWithErrors;
};

export default validateForm;
